<template>
  <!-- Begin our services -->
  <section class="section services">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wrapp-section-title section-title-center">
            <div class="section-subtitle">Areas what we serv</div>
            <h2 class="section-title">{{title}}</h2>
          </div>
        </div>

        <development />

      </div>
    </div>
  </section>
  <!-- End our serveces -->
</template>

<script>
export default {
  name: "Develop",
  data() {
    return {
      title: "Develop"
    };
  }
};
</script>

<style>
</style>